import React, { useEffect } from "react";
import LoadingCircle from "./utilities/LoadingCircle";

function LoadUserInfo(props) {
  useEffect(() => {
    props.getUserInfo();
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <LoadingCircle />
      </div>
    </div>
  );
}

export default LoadUserInfo;
