import React, { useState, useEffect } from "react";

export default function Ticket(props) {
  useEffect(() => {
    const timer = setTimeout(() => {
      props.startPage();
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  function phoneFormat() {
    let input = props.phoneNumber;
    input = input.replace(/\D/g, "");

    input = input.substring(0, 10);

    var size = input.length;
    if (size == 0) {
      input = input;
    } else if (size < 4) {
      input = "(" + input;
    } else if (size < 7) {
      input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
    } else {
      input =
        "(" +
        input.substring(0, 3) +
        ") " +
        input.substring(3, 6) +
        " - " +
        input.substring(6, 10);
    }
    return input;
  }
  return (
    <div className="ticket-total-container">
      <h1>You're all set! Your ticket number is:</h1>
      <div className="ticket-number-container">
        <h1>{props.ticketNumber}</h1>
      </div>
      <h2>We will notify you when your table is ready!</h2>
      <h3>
        Please wait nearby and check{" "}
        {props.phoneNumber.length > 0
          ? `for updates at ${phoneFormat(props.phoneNumber)}!`
          : "the waitlist for live updates!"}
      </h3>
      <button className="ticket-finish-btn" onClick={props.startPage}>
        Finish
      </button>
    </div>
  );
}
