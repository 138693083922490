import React, { useState, useEffect } from "react";
import SpeedIcon from "@mui/icons-material/Speed";
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";
import LocalBarIcon from "@mui/icons-material/LocalBar";
import DeckIcon from "@mui/icons-material/Deck";
import CloseIcon from "@mui/icons-material/Close";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import EventNoteIcon from "@mui/icons-material/EventNote";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";

export default function TableRow(props) {
  const [waitTimeMinutes, setWaitTimeMinutes] = useState();
  const [notifiedTimeMinutes, setNotifiedTimeMinutes] = useState();
  const [notifiedDateTime, setNotifiedDateTime] = useState(
    new Date(Date.parse(props.row.notified_time))
  );
  var entered_date_time = new Date(Date.parse(props.row.entered_time));

  function updateWaitMinutes() {
    const initialDate = entered_date_time.getTime();
    const now = new Date().getTime();
    const difference = now - initialDate;
    var hours = Math.floor((difference % 86400000) / 3600000);
    let minutes = Math.floor(((difference % 86400000) % 3600000) / 60000);
    minutes = minutes + hours * 60;
    setWaitTimeMinutes(minutes);
  }

  function phoneFormat(input) {
    input = input.replace(/\D/g, "");

    input = input.substring(0, 10);

    var size = input.length;
    if (size == 0) {
      input = input;
    } else if (size < 4) {
      input = "(" + input;
    } else if (size < 7) {
      input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
    } else {
      input =
        "(" +
        input.substring(0, 3) +
        ") " +
        input.substring(3, 6) +
        " - " +
        input.substring(6, 10);
    }
    return input;
  }

  function updateNotifiedMinutes() {
    const initialDate = notifiedDateTime.getTime();
    const now = new Date().getTime();
    const difference = now - initialDate;
    var hours = Math.floor((difference % 86400000) / 3600000);
    let minutes = Math.floor(((difference % 86400000) % 3600000) / 60000);
    minutes = minutes + hours * 60;
    setNotifiedTimeMinutes(minutes);
  }

  useEffect(() => {
    updateWaitMinutes();
    const interval = setInterval(() => {
      updateWaitMinutes();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    updateNotifiedMinutes();
    const interval = setInterval(() => {
      updateNotifiedMinutes();
    }, 60000);

    return () => clearInterval(interval);
  }, [notifiedDateTime]);

  function handleAlert(id) {
    props.alert(id);
    var date = new Date();
    var copiedDate = new Date(date.getTime());
    setNotifiedDateTime(copiedDate);
    updateNotifiedMinutes();
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const noteOnOpen = props.row.note;

  function handleCloseNoSave() {
    setNote(noteOnOpen);
    handleClose();
  }

  const [note, setNote] = React.useState(props.row.note);

  let actionButton1;
  let actionButton2;
  let actionButton3;
  if (props.row.customer_number.length > 0) {
    actionButton1 = "Alert";
    actionButton2 = "Alert Again"; // "Alert Again"
    actionButton3 = "Alerted 2x";
  } else {
    actionButton1 = "Call Name";
    actionButton2 = "Called Name";
    actionButton3 = "Called Name";
  }

  function addNote() {
    console.log(note);
    console.log("saved");
    props.saveNote(props.row.id, note);
    handleClose();
  }

  return (
    <div className="dashboard-table-row">
      <p className="header1">{props.row.ticket_id}</p>
      <div className="header2">
        <p className="header2-name">{props.row.customer_name}</p>
        <p className="header2-number">
          {phoneFormat(props.row.customer_number)}
        </p>
      </div>
      <p className="header3">{props.row.party_size}</p>
      <p className="header4">
        <HourglassBottomIcon />
        {isNaN(notifiedTimeMinutes)
          ? waitTimeMinutes
          : waitTimeMinutes + " | " + notifiedTimeMinutes}
      </p>
      <div className="header5">
        {props.row.first_available === 1 ? <SpeedIcon /> : <div />}
        {props.row.indoor_area === 1 ? <TableRestaurantIcon /> : <div />}
        {props.row.bar_area === 1 ? <LocalBarIcon /> : <div />}
        {props.row.outdoor_area === 1 ? <DeckIcon /> : <div />}
      </div>
      <div className="header6-btn-container">
        {props.row.notified === 0 ? (
          <button
            className="alert-btn"
            onClick={() => handleAlert(props.row.id)}
          >
            {actionButton1}
          </button>
        ) : props.row.notified === 1 ? (
          <button
            className="alert-btn-notified"
            onClick={() => props.alertAgain(props.row.id)}
          >
            {actionButton2}
          </button>
        ) : (
          <button className="alert-btn-notified">{actionButton3}</button>
        )}
        <button className="seat-btn" onClick={() => props.seat(props.row.id)}>
          Seat
        </button>
        <button className={note ? "note" : "note-btn"} onClick={handleOpen}>
          <EventNoteIcon />
        </button>
        <button
          className="delete-btn"
          onClick={() => props.deleteRow(props.row.id)}
        >
          <CloseIcon />
        </button>
      </div>
      <Modal
        open={open}
        onClose={handleCloseNoSave}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="modal-box">
            <div className="modal-close">
              <CloseIcon onClick={handleCloseNoSave} />
            </div>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {"Note - " + props.row.customer_name}
            </Typography>
            <div className="modal-note-input">
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Note"
                multiline
                rows={4}
                value={note}
                onChange={(event) => {
                  setNote(event.target.value);
                }}
              />
            </div>
            <div className="modal-save-btn">
              <Button
                variant="contained"
                style={{ backgroundColor: "#e32d2d" }}
                onClick={() => addNote()}
              >
                Save
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
