import React, { useState, useEffect } from "react";
import Navbar from "./Navbar.js";
import WaitSummary from "./WaitSummary.js";
import TableHeader from "./TableHeader.js";
import TableRow from "./TableRow.js";
import LoadingCircle from "./utilities/LoadingCircle.js";
import {
  API_get_waiting_customers,
  API_remove_customer,
  API_seat_customer,
  API_notify_customer,
  API_save_note,
  API_notify_customer_again,
} from "./constants.js";
import axios from "axios";

export default function Dashboard(props) {
  const [actualTableData, setActualTableData] = useState();
  const [loadComplete, setLoadComplete] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      getWaitingCustomers();
    }, 10000); // every 10 seconds
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    getWaitingCustomers();
  }, []);

  function alert(id, phone) {
    axios.put(API_notify_customer + id).then(() => {
      getWaitingCustomers();
    });
    console.log("Alerted");
  }

  function alertAgain(id) {
    console.log("Alerted Again");
    axios.put(API_notify_customer_again + id).then(() => {
      getWaitingCustomers();
    });
  }

  function seat(id) {
    axios.put(API_seat_customer + id).then(() => {
      getWaitingCustomers();
    });
    console.log("Seated");
  }

  function deleteRow(id) {
    axios.put(API_remove_customer + id).then(() => {
      getWaitingCustomers();
    });
    console.log("Deleted Row");
  }

  function saveNote(id, note) {
    const note_text = { note: note };
    axios.put(API_save_note + id, note_text).then(() => {
      getWaitingCustomers();
    });
  }

  function getWaitingCustomers() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    var today_str = yyyy + "-" + mm + "-" + dd;
    axios
      .get(
        API_get_waiting_customers +
          props.company +
          "&store=" +
          props.store_num +
          "&date=" +
          today_str
      )
      .then((df) => {
        setActualTableData(df.data);
        setLoadComplete(true);
      });
  }

  return (
    <div className="dashboard-total-container">
      <WaitSummary
        company={props.company}
        store_num={props.store_num}
        actualTableData={actualTableData}
      />
      <TableHeader />
      {loadComplete ? (
        actualTableData.map((row) => (
          <TableRow
            key={row.id}
            row={row}
            alert={alert}
            alertAgain={alertAgain}
            seat={seat}
            deleteRow={deleteRow}
            saveNote={saveNote}
          />
        ))
      ) : (
        <LoadingCircle />
      )}
    </div>
  );
}
