import React, { useState, useEffect } from "react";
import SpeedIcon from "@mui/icons-material/Speed";
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";
import LocalBarIcon from "@mui/icons-material/LocalBar";
import DeckIcon from "@mui/icons-material/Deck";
import WaitSummary from "./WaitSummary.js";
import { API_get_waiting_customers } from "./constants.js";
import axios from "axios";

export default function Party(props) {
  useEffect(() => {
    const timer = setTimeout(() => {
      props.startPage();
    }, props.timeOutValue);
    return () => clearTimeout(timer);
  }, []);

  const [actualTableData, setActualTableData] = useState();

  function getWaitingCustomers() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    var today_str = yyyy + "-" + mm + "-" + dd;
    axios
      .get(
        API_get_waiting_customers +
          props.company +
          "&store=" +
          props.store_num +
          "&date=" +
          today_str
      )
      .then((df) => {
        setActualTableData(df.data);
      });
  }

  return (
    <div className="party-container">
      <h1 className="party-title">Enter Party Size</h1>
      <div className="party-counter-container">
        {props.partySize > 0 && (
          <button
            className="party-decrement-btn"
            onClick={props.decrementParty}
          >
            -
          </button>
        )}
        <h1 className="party-count">{props.partySize}</h1>
        <button className="party-increment-btn" onClick={props.incrementParty}>
          +
        </button>
      </div>
      <div className="party-information-container">
        {props.firstAvailableOkaySetting && (
          <button
            className={
              props.firstAvailableOkay
                ? "party-information-btn1"
                : "party-information-btn"
            }
            onClick={props.selectFirstAvailable}
          >
            <SpeedIcon />
            <br />
            First Available <br />
            Okay
          </button>
        )}
        {props.indoorSeatingPreferredSetting && (
          <button
            className={
              props.indoorSeatingPreferred
                ? "party-information-btn1"
                : "party-information-btn"
            }
            onClick={props.selectIndoor}
          >
            <TableRestaurantIcon />
            <br />
            Indoor Seating Preferred
          </button>
        )}
        {props.barSeatingPreferredSetting && (
          <button
            className={
              props.barSeatingPreferred
                ? "party-information-btn1"
                : "party-information-btn"
            }
            onClick={props.selectBar}
          >
            <LocalBarIcon />
            <br />
            Bar Seating
            <br />
            Preferred
          </button>
        )}
        {props.outdoorSeatingPreferredSetting && (
          <button
            className={
              props.outdoorSeatingPreferred
                ? "party-information-btn1"
                : "party-information-btn"
            }
            onClick={props.selectOutdoor}
          >
            <DeckIcon />
            <br />
            Outdoor Seating Preferred
          </button>
        )}
      </div>
      <div className="party-wait-summary-container">
        <WaitSummary
          company={props.company}
          store_num={props.store_num}
          actualTableData={actualTableData}
        />
      </div>
      <div className="party-button-container">
        <button className="party-back-btn" onClick={props.prevPage}>
          BACK
        </button>
        {props.partySize < 1 ? (
          <button className="party-next-btn0">NEXT</button>
        ) : (
          <button className="party-next-btn1" onClick={props.nextPage}>
            NEXT
          </button>
        )}
      </div>
    </div>
  );
}
