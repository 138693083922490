import React from "react";

export default function ArchiveRow(props) {

  function phoneFormat(input) {
    input = input.replace(/\D/g, "");

    input = input.substring(0, 10);

    var size = input.length;
    if (size == 0) {
      input = input;
    } else if (size < 4) {
      input = "(" + input;
    } else if (size < 7) {
      input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
    } else {
      input =
        "(" +
        input.substring(0, 3) +
        ") " +
        input.substring(3, 6) +
        " - " +
        input.substring(6, 10);
    }
    return input;
  }

  return (
    <div className="archive-table-row">
      <p className="archive-header1">{props.row.ticket_id}</p>
      <div className="archive-header2">
        <p className="header2-name">{props.row.customer_name}</p>
        <p className="header2-number">{phoneFormat(props.row.customer_number)}</p>
      </div>
      <p className="archive-header3">{props.row.party_size}</p>
      <p className="archive-header4">
        {Math.round(props.row.actual_waited_time, 0) + " min"}
      </p>
      <p className="archive-header5">
        {props.row.seated === 1 ? "seated" : "no show"} {props.row.notified === 1 ? "(notified)" : ""}
      </p>
      <div className="archive-header6">
        <button
          className="put-back-btn"
          onClick={() => props.putBack(props.row.id)}
        >
          Put back
        </button>
      </div>
    </div>
  );
}
