import React from "react";
import TableChartIcon from "@mui/icons-material/TableChart";
import ArchiveIcon from "@mui/icons-material/Archive";
import SettingsIcon from "@mui/icons-material/Settings";

export default function Navbar(props) {
  return (
    <div className="dashboard-navbar-container">
      <div className="dashboard-logo-container">
        <img
          className="navbar-logo-image"
          src={process.env.PUBLIC_URL + "/images/kizuki_white.png"}
        />
      </div>
      <button
        className={props.tab === 1 ? "navigation-btn1" : "navigation-btn"}
        onClick={props.dashboardTab}
      >
        <TableChartIcon fontSize="small" /> Dashboard
      </button>
      <button
        className={props.tab === 2 ? "navigation-btn1" : "navigation-btn"}
        onClick={props.archiveTab}
      >
        <ArchiveIcon fontSize="small" /> Archive
      </button>
      <button
        className={props.tab === 3 ? "navigation-btn1" : "navigation-btn"}
        onClick={props.settingsTab}
      >
        <SettingsIcon fontSize="small" /> Settings
      </button>
    </div>
  );
}
