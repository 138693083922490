import React from "react";

export default function TableHeader() {
  return (
    <div className="dashboard-table-header-container">
      <p className="header1">Ticket</p>
      <p className="header2">ID</p>
      <p className="header3">Size</p>
      <p className="header4">Time (min)</p>
      <p className="header5">Pref</p>
      <p className="header6">Action</p>
    </div>
  );
}
