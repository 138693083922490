import React, { useState, useEffect } from "react";
import LoadingCircle from "./utilities/LoadingCircle.js";
import { API_get_waiting_numbers } from "./constants.js";
import axios from "axios";

export default function Welcome(props) {
  const [waitingNumbers, setWaitingNumbers] = useState();
  const [loadComplete, setLoadComplete] = useState(false);

  useEffect(() => {
    getWaitingNumbers();
  }, [props.store_num]);

  function getWaitingNumbers() {
    axios
      .get(
        API_get_waiting_numbers + props.company + "&store=" + props.store_num
      )
      .then((df) => {
        setWaitingNumbers(df.data);
        setLoadComplete(true);
      });
  }

  function showSpotInLine(number) {
    if (number === 1) {
      return "Congrats! You are the first in line.";
    } else {
      return "You are position " + number + " in line!";
    }
  }

  function showWelcomeInfo() {
    if (props.waitlist_onoff) {
      return (
        <div>
          <div className="welcome-information">
            {props.announcement && props.announcement.length > 0 && (
              <div className="welcome-announcement">{props.announcement}</div>
            )}
            <h4>
              Please press start button to enter your party and see expected
              wait times.
            </h4>
            <p className="welcome-paragraph">
              We will be happy to seat you once your{" "}
              <b>ENTIRE PARTY HAS ARRIVED</b>.<br />
              Thanks for your patience and understanding!
            </p>
          </div>
          <div className="welcome-information">
            <button className="welcome-start-btn" onClick={props.nextPage}>
              START
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="welcome-information">
            <h1>Please wait to be seated!</h1>
          </div>
        </div>
      );
    }
  }

  function showWaitNumbers() {
    if (loadComplete) {
      return (
        <div>
          <h3>
            Total parties:{" "}
            <span className="welcome-sidebar-content-numbers">
              {waitingNumbers["parties"]}
            </span>
          </h3>
          <h3>
            Solo diners:{" "}
            <span className="welcome-sidebar-content-numbers">
              {waitingNumbers["solo"]}
            </span>
          </h3>
          <br />
          <br />
          <h3>
            {showSpotInLine(
              waitingNumbers["parties"] + waitingNumbers["solo"] + 1
            )}
          </h3>
        </div>
      );
    } else {
      return <LoadingCircle />;
    }
  }

  return (
    <div className="welcome-container">
      <div className="welcome-sidebar">
        <div className="welcome-sidebar-content">{showWaitNumbers()}</div>
      </div>
      <div className="welcome-content">
        <div className="welcome-heading">
          <img
            className="welcome-logo-image"
            src={
              props.email.toLowerCase() === "alderwood@swish-swish.com"
                ? process.env.PUBLIC_URL + "/images/swish_black.png"
                : process.env.PUBLIC_URL + "/images/kizuki_black.png"
            }
          />
        </div>
        <div className="welcome-image-placeholder">
          <img
            className="welcome-image"
            src={process.env.PUBLIC_URL + "/images/ramen_image.png"}
          />
        </div>
        {showWelcomeInfo()}
      </div>
      <div className="powered-by-logo-container">
        <h6>Powered by </h6>
        <img
          className="powered-by-logo-image"
          src={process.env.PUBLIC_URL + "/images/logo_white.png"}
        />
      </div>
    </div>
  );
}
