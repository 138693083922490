import React, { useState, useEffect } from "react";
import LoadingCircle from "./utilities/LoadingCircle.js";
import {
  API_get_table_settings,
  API_change_table_settings,
} from "./constants.js";
import axios from "axios";

export default function Settings(props) {
  const [settingsData, setSettingsData] = useState();
  const [oldSettingsData, setOldSettingsData] = useState();
  const [loadComplete, setLoadComplete] = useState(false);
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [announcementCopy, setAnnouncementCopy] = useState(props.announcement);
  const [saveAnnouncementEnabled, setSaveAnnouncementEnabled] = useState(false);

  const oldAnnouncementCopy = props.announcement;

  useEffect(() => {
    getTableSettings();
  }, [props.store_num]);

  function getTableSettings() {
    axios
      .get(API_get_table_settings + props.company + "&store=" + props.store_num)
      .then((df) => {
        setSettingsData(df.data);
        setOldSettingsData(df.data);
        setLoadComplete(true);
      });
  }

  function changeValues(event) {
    const name_var = event.target.name;
    var settingsDataCopy = { ...settingsData };
    settingsDataCopy[name_var] = parseInt(event.target.value);
    setSettingsData(settingsDataCopy);
    enableButton(settingsDataCopy);
  }

  function changeAnnouncement(event) {
    setAnnouncementCopy(event.target.value);
    enableSaveAnnouncementButton(event.target.value);
  }

  function enableSaveAnnouncementButton(text) {
    if (oldAnnouncementCopy !== text) {
      setSaveAnnouncementEnabled(true);
    } else {
      setSaveAnnouncementEnabled(false);
    }
  }

  function onSave() {
    console.log("Save");
    axios.post(API_change_table_settings, settingsData).then(() => {
      alert("Settings Updated Successfully!");
      getTableSettings();
    });
  }

  function enableButton(settingsDataTemp) {
    if (
      JSON.stringify(oldSettingsData) !== JSON.stringify(settingsDataTemp) &&
      !ifNull(settingsDataTemp)
    ) {
      setSaveEnabled(true);
    } else {
      setSaveEnabled(false);
    }
  }

  function ifNull(obj) {
    for (var key in obj) {
      if (key !== "company") {
        if (obj[key] === null || obj[key] === "" || isNaN(obj[key]))
          return true;
      }
    }
    return false;
  }

  function signOut() {
    props.Logout();
    console.log("Log Out");
  }

  function replaceNull(value) {
    if (value == null) {
      return "";
    } else {
      return value;
    }
  }
  //console.log(saveEnabled);
  //console.log(settingsData);
  return (
    <div className="settings-total-container">
      <div className="settings-container">
        <h1 className="settings-title">Settings</h1>
        <h2 className="settings-subtitle">
          For technical support call (360) 708-9208
        </h2>
        <hr className="settings-horizontal-line" />
        <div className="settings-inputs-title">
          <h4>
            Add Announcement to Home Screen{" "}
            <span className="settings-announcement-title-span"></span>
          </h4>
        </div>
        <div className="settings-inputs-container">
          <div className="input-announcement-container">
            <label className="settings-label">Announcement</label>
            <input
              className="settings-announcement-input"
              placeholder="Add announcement here"
              name="Announcement"
              value={announcementCopy}
              onChange={changeAnnouncement}
            />
          </div>
          <button
            className="annoucement-save-btn"
            onClick={() => props.saveAnnouncement(announcementCopy)}
            disabled={!saveAnnouncementEnabled}
            style={{
              background: saveAnnouncementEnabled ? "white" : "#eeeeee",
            }}
          >
            Save
          </button>
        </div>
        <hr className="settings-horizontal-line" />
        <div className="settings-inputs-title">
          <h4>Table Information</h4>
        </div>
        {loadComplete ? (
          <div className="settings-inputs-container">
            <div className="input-container">
              <label className="settings-label">Solo Tables Total</label>
              <input
                className="settings-input"
                type="number"
                placeholder="# of Tables"
                name="solo_tables_total"
                onChange={changeValues}
                value={replaceNull(settingsData["solo_tables_total"])}
              ></input>
            </div>
            <div className="input-container">
              <label className="settings-label">Solo Table Turn Average</label>
              <input
                className="settings-input"
                type="number"
                placeholder="Minutes per Table"
                name="solo_tables_turn_avg"
                onChange={changeValues}
                value={replaceNull(settingsData["solo_tables_turn_avg"])}
              ></input>
            </div>
            <div className="input-container">
              <label className="settings-label">Two-Top Tables Total</label>
              <input
                className="settings-input"
                type="number"
                placeholder="# of Tables"
                name="two_tables_total"
                onChange={changeValues}
                value={replaceNull(settingsData["two_tables_total"])}
              ></input>
            </div>
            <div className="input-container">
              <label className="settings-label">
                Two-Top Tables Turn Average
              </label>
              <input
                className="settings-input"
                type="number"
                placeholder="Minutes per Table"
                name="two_tables_turn_avg"
                onChange={changeValues}
                value={replaceNull(settingsData["two_tables_turn_avg"])}
              ></input>
            </div>
            <div className="input-container">
              <label className="settings-label">Three/Four Tables Total</label>
              <input
                className="settings-input"
                type="number"
                placeholder="# of Tables"
                name="three_four_tables_total"
                onChange={changeValues}
                value={replaceNull(settingsData["three_four_tables_total"])}
              ></input>
            </div>
            <div className="input-container">
              <label className="settings-label">
                Three/Four Table Turn Average
              </label>
              <input
                className="settings-input"
                type="number"
                placeholder="Minutes per Table"
                name="three_four_tables_turn_avg"
                onChange={changeValues}
                value={replaceNull(settingsData["three_four_tables_turn_avg"])}
              ></input>
            </div>
            <div className="input-container">
              <label className="settings-label">Five+ Tables Total</label>
              <input
                className="settings-input"
                type="number"
                placeholder="# of Tables"
                name="five_plus_tables_total"
                onChange={changeValues}
                value={replaceNull(settingsData["five_plus_tables_total"])}
              ></input>
            </div>
            <div className="input-container">
              <label className="settings-label">Five+ Table Turn Average</label>
              <input
                className="settings-input"
                type="number"
                placeholder="Minutes per Table"
                name="five_plus_tables_turn_avg"
                onChange={changeValues}
                value={replaceNull(settingsData["five_plus_tables_turn_avg"])}
              ></input>
            </div>
            <button
              className="settings-save-btn"
              onClick={() => onSave()}
              disabled={!saveEnabled}
              style={{
                background: saveEnabled ? "white" : "#eeeeee",
              }}
            >
              Save
            </button>
          </div>
        ) : (
          <LoadingCircle />
        )}
        <hr className="settings-horizontal-line" />
        <div className="settings-party-input-container">
          <h2 className="settings-party-input-container-title">
            Party Input Options
          </h2>
          <div className="settings-party-input-sub-container">
            <h5>First Available Okay</h5>
            <div
              className={
                props.firstAvailableOkaySetting
                  ? "toggle-design"
                  : "toggle-design1"
              }
              onClick={() =>
                props.savePartyInputOptions("first_available_okay")
              }
            >
              <div
                className={
                  props.firstAvailableOkaySetting
                    ? "toggle-design-ball"
                    : "toggle-design-ball1"
                }
              ></div>
            </div>
          </div>
          <div className="settings-party-input-sub-container">
            <h5>Indoor Seating Preferred</h5>
            <div
              className={
                props.indoorSeatingPreferredSetting
                  ? "toggle-design"
                  : "toggle-design1"
              }
              onClick={() =>
                props.savePartyInputOptions("indoor_seating_preferred")
              }
            >
              <div
                className={
                  props.indoorSeatingPreferredSetting
                    ? "toggle-design-ball"
                    : "toggle-design-ball1"
                }
              ></div>
            </div>
          </div>
          <div className="settings-party-input-sub-container">
            <h5>Bar Seating Preferred</h5>
            <div
              className={
                props.barSeatingPreferredSetting
                  ? "toggle-design"
                  : "toggle-design1"
              }
              onClick={() =>
                props.savePartyInputOptions("bar_seating_preferred")
              }
            >
              <div
                className={
                  props.barSeatingPreferredSetting
                    ? "toggle-design-ball"
                    : "toggle-design-ball1"
                }
              ></div>
            </div>
          </div>
          <div className="settings-party-input-sub-container">
            <h5>Outdoor Seating Preferred</h5>
            <div
              className={
                props.outdoorSeatingPreferredSetting
                  ? "toggle-design"
                  : "toggle-design1"
              }
              onClick={() =>
                props.savePartyInputOptions("outdoor_seating_preferred")
              }
            >
              <div
                className={
                  props.outdoorSeatingPreferredSetting
                    ? "toggle-design-ball"
                    : "toggle-design-ball1"
                }
              ></div>
            </div>
          </div>
        </div>
        <hr className="settings-horizontal-line" />
        <div
          className="remote-checkin-toggle-container"
          onClick={() => props.savePartyInputOptions("waitlist_onoff")}
        >
          <h2 className="settings-toggle-title">Waitlist On/Off</h2>
          <div
            className={
              props.waitlistOnOffSetting ? "toggle-design" : "toggle-design1"
            }
          >
            <div
              className={
                props.waitlistOnOffSetting
                  ? "toggle-design-ball"
                  : "toggle-design-ball1"
              }
            ></div>
          </div>
        </div>
        <div>
          <button className="sign-out-btn" onClick={() => signOut()}>
            Sign Out
          </button>
        </div>
      </div>
    </div>
  );
}

/*
        <div
          className="remote-checkin-toggle-container"
          onClick={props.toggleRemoteEnabled}
        >
          <h2 className="settings-toggle-title">
            Enable/Disable Remote Check-in
          </h2>
          <div
            className={props.remoteEnabled ? "toggle-design" : "toggle-design1"}
          >
            <div
              className={
                props.remoteEnabled
                  ? "toggle-design-ball"
                  : "toggle-design-ball1"
              }
            ></div>
          </div>
        </div>
*/
