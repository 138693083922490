import React, { useState, useEffect } from "react";

import CloseIcon from "@mui/icons-material/Close";

export default function Input(props) {
  useEffect(() => {
    const timer = setTimeout(() => {
      props.startPage();
    }, props.timeOutValue);
    return () => clearTimeout(timer);
  }, []);

  function phoneFormat() {
    let input = props.phoneNumber;
    input = input.replace(/\D/g, "");

    input = input.substring(0, 10);

    var size = input.length;
    if (size == 0) {
      input = input;
    } else if (size < 4) {
      input = "(" + input;
    } else if (size < 7) {
      input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
    } else {
      input =
        "(" +
        input.substring(0, 3) +
        ") " +
        input.substring(3, 6) +
        " - " +
        input.substring(6, 10);
    }
    return input;
  }

  return (
    <div className="input-total-container">
      <div className="input-number-container">
        <h3 className="input-number-title">Receive a text alert when your table is ready</h3>
        {props.phoneNumber.length > 0 ? (
          <div className="input-number-display1">{phoneFormat()}</div>
        ) : (
          <div className="input-number-display">Enter your number</div>
        )}
        <div className="input-numbers">
          <button
            className="number-btn"
            value={1}
            onClick={props.inputPhoneNumber}
          >
            1
          </button>
          <button
            className="number-btn"
            value={2}
            onClick={props.inputPhoneNumber}
          >
            2
          </button>
          <button
            className="number-btn"
            value={3}
            onClick={props.inputPhoneNumber}
          >
            3
          </button>
          <button
            className="number-btn"
            value={4}
            onClick={props.inputPhoneNumber}
          >
            4
          </button>
          <button
            className="number-btn"
            value={5}
            onClick={props.inputPhoneNumber}
          >
            5
          </button>
          <button
            className="number-btn"
            value={6}
            onClick={props.inputPhoneNumber}
          >
            6
          </button>
          <button
            className="number-btn"
            value={7}
            onClick={props.inputPhoneNumber}
          >
            7
          </button>
          <button
            className="number-btn"
            value={8}
            onClick={props.inputPhoneNumber}
          >
            8
          </button>
          <button
            className="number-btn"
            value={9}
            onClick={props.inputPhoneNumber}
          >
            9
          </button>
          <button className="number-btn" onClick={props.clearPhoneNumber}>
            C
          </button>
          <button
            className="number-btn"
            value={0}
            onClick={props.inputPhoneNumber}
          >
            0
          </button>
          <button
            className="number-btn"
            onClick={props.deleteElementPhoneNumber}
          >
            <CloseIcon style={{ fontSize: 32 }} />
          </button>
        </div>
      </div>
      <div className="input-or">
        <h2>O</h2>
        <h2>R</h2>
      </div>
      <div className="input-name-container">
        <h3 className="input-name-title">Skip and we will call your name when your table is ready</h3>
        <button className="input-name-btn" onClick={props.addCustomerToWaitList}>
          Skip
        </button>
      </div>

      <div className="input-button-container">
        <button className="input-back-btn" onClick={props.prevPage}>
          BACK
        </button>
        {props.phoneNumber.length < 10 ? (
          <button className="input-next-btn0">GET A TICKET</button>
        ) : (
          <button
            className="input-next-btn1"
            onClick={props.addCustomerToWaitList}
          >
            GET A TICKET
          </button>
        )}
      </div>
    </div>
  );
}
