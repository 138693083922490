import axios from "axios";
import * as actionTypes from "./actionTypes";
import { API_REGISTRATION, API_LOGIN } from "../../constants";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token, user, email) => {
  localStorage.setItem("token", token);
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
    user: user,
    email: email,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("expirationDate");
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000 * 24 * 1);
  };
};

export const authLogin = (username, password, switch_to_logged_in) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      //.post("http://localhost:8000/api/dj-rest-auth/login/", {
      .post(API_LOGIN, {
        username: username,
        password: password,
      })
      .then((res) => {
        const token = res.data.key;
        const expirationDate = new Date(
          new Date().getTime() + 3600 * 1000 * 24 * 1
        );
        localStorage.setItem("token", token);
        localStorage.setItem("user", username);
        localStorage.setItem("email", username);
        localStorage.setItem("expirationDate", expirationDate);
        dispatch(authSuccess(token, username, username));
        dispatch(checkAuthTimeout(3600));
        switch_to_logged_in();
      })
      .catch((err) => {
        //amplitude.getInstance().logEvent("Login Error");
        alert("Please try a different username or password.");
        dispatch(authFail(err));
      });
  };
};

export const authSignup = (username, email, password1, password2) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      //.post("http://localhost:8000/api/dj-rest-auth/registration/", {
      .post(API_REGISTRATION, {
        username: username,
        email: email,
        password1: password1,
        password2: password2,
      })
      .then((res) => {
        const token = res.data.key;
        const expirationDate = new Date(
          new Date().getTime() + 3600 * 1000 * 24 * 1
        );
        localStorage.setItem("token", token);
        localStorage.setItem("user", username);
        localStorage.setItem("email", email);
        localStorage.setItem("expirationDate", expirationDate);
        dispatch(authSuccess(token, username, email));
        dispatch(checkAuthTimeout(3600));
      })
      .catch((err) => {
        console.log(err);
        alert("Please try a different username or password.");
        dispatch(authFail(err));
      });
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    const email = localStorage.getItem("email");
    if (token === undefined) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      if (expirationDate <= new Date()) {
        dispatch(logout());
      } else {
        dispatch(authSuccess(token, user, email));
        dispatch(checkAuthTimeout(3600));
      }
    }
  };
};
