import logo from "./logo.svg";
import React, { useState, useEffect, Component } from "react";
import Welcome from "./Welcome.js";
import Party from "./Party.js";
import Input from "./Input.js";
import Name from "./Name.js";
import Ticket from "./Ticket.js";
import Dashboard from "./Dashboard";
import App2 from "./App2.js";
import "./App.css";
import NotificationModal from "./test/NotificationModal";
import axios from "axios";
import Login from "./Login";
import {
  API_create_test_message,
  API_add_new_customer,
  API_get_user_info,
  API_get_ticket,
  API_get_party_input_settings,
  API_change_party_input_settings,
} from "./constants";
import * as actions from "./store/actions/auth";
import { connect } from "react-redux";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import SplitscreenIcon from "@mui/icons-material/Splitscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import LoadingCircle from "./utilities/LoadingCircle";
import LoadUserInfo from "./LoadUserInfo";

export function App(props) {
  const [company, setCompany] = useState("");
  const [store_num, setStoreNum] = useState(0);
  const [store_name, setStoreName] = useState("Test Store");
  const [page, setPage] = useState(1);
  const [partySize, setPartySize] = useState(1);
  const [finishedLoading, setFinishedLoading] = useState(false);

  //const [ticketCnt, setTicketCnt] = useState(0);
  //const [ticketLetter, setTicketLetter] = useState("a");
  const [ticketNumber, setTicketNumber] = useState("A0");

  const [firstAvailableOkay, setFirstAvailableOkay] = useState(true);
  const [indoorSeatingPreferred, setIndoorSeatingPreferred] = useState(false);
  const [barSeatingPreferred, setBarSeatingPreferred] = useState(false);
  const [outdoorSeatingPreferred, setOutdoorSeatingPreferred] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");

  const [firstAvailableOkaySetting, setFirstAvailableOkaySetting] =
    useState(true);
  const [indoorSeatingPreferredSetting, setIndoorSeatingPreferredSetting] =
    useState(true);
  const [barSeatingPreferredSetting, setBarSeatingPreferredSetting] =
    useState(true);
  const [outdoorSeatingPreferredSetting, setOutdoorSeatingPreferredSetting] =
    useState(true);

  const [partyInputsSettings, setPartyInputsSettings] = useState(null);

  const [timeOutValue, setTimeOutValue] = useState(1000 * 60 * 1.5);

  useEffect(() => {
    props.onTryAutoSignup();
  }, []);

  const sendIntroMessage = (tick_num) => {
    const notification = {
      phone: phoneNumber,
      ticket_number: tick_num,
    };
    axios.post(API_create_test_message, notification).then(() => {
      console.log("Message Sent");
    });
  };

  function getPartyInputOptions(company, store) {
    axios
      .get(API_get_party_input_settings + company + "&store=" + store)
      .then((df) => {
        setPartyInputsSettings(df.data);
        /*
        console.log(df.data);
        setFirstAvailableOkaySetting(
          df.data.first_available_okay === 1 ? true : false
        );
        setIndoorSeatingPreferredSetting(
          df.data.indoor_seating_preferred === 1 ? true : false
        );
        setBarSeatingPreferredSetting(
          df.data.bar_seating_preferred === 1 ? true : false
        );
        setOutdoorSeatingPreferredSetting(
          df.data.outdoor_seating_preferred === 1 ? true : false
        );
        */
      });
  }

  function savePartyInputOptions(change_name) {
    const cur_val = partyInputsSettings[change_name];
    const new_val = cur_val === 1 ? 0 : 1;
    var partyDataCopy = { ...partyInputsSettings };
    partyDataCopy[change_name] = new_val;

    axios.post(API_change_party_input_settings, partyDataCopy).then(() => {
      getPartyInputOptions(company, store_num);
    });
  }

  function saveAnnouncement(new_val) {
    var partyDataCopy = { ...partyInputsSettings };
    partyDataCopy["announcement"] = new_val;

    axios.post(API_change_party_input_settings, partyDataCopy).then(() => {
      getPartyInputOptions(company, store_num);
    });
  }

  function getUserInfo() {
    axios.get(API_get_user_info + props.username).then((df) => {
      setCompany(df.data.company);
      setStoreNum(df.data.store);
      setFinishedLoading(true);
      incrementTicket(df.data.company, df.data.store);
      getPartyInputOptions(df.data.company, df.data.store);
      //setStoreName(df.data.store_name);
    });
  }

  function resetInputs() {
    setPhoneNumber("");
    setName("");
    setPartySize(1);
    setFirstAvailableOkay(true);
    setIndoorSeatingPreferred(false);
    setBarSeatingPreferred(false);
    setOutdoorSeatingPreferred(false);
  }

  function nextPage() {
    setPage((prevState) => (prevState += 1));
  }

  function prevPage() {
    setPage((prevState) => (prevState -= 1));
  }

  function ticketPage() {
    setPage(5);
  }

  function startPage() {
    switch_to_home();
    resetInputs();
    incrementTicket(company, store_num);
  }

  function incrementParty() {
    setPartySize((prevState) => (prevState += 1));
  }

  function decrementParty() {
    setPartySize((prevState) => (prevState -= 1));
  }

  function selectFirstAvailable() {
    setFirstAvailableOkay(true);
    setIndoorSeatingPreferred(false);
    setBarSeatingPreferred(false);
    setOutdoorSeatingPreferred(false);
  }

  function selectIndoor() {
    setFirstAvailableOkay(false);
    setIndoorSeatingPreferred(true);
    setBarSeatingPreferred(false);
    setOutdoorSeatingPreferred(false);
  }

  function selectBar() {
    setFirstAvailableOkay(false);
    setIndoorSeatingPreferred(false);
    setBarSeatingPreferred(true);
    setOutdoorSeatingPreferred(false);
  }

  function selectOutdoor() {
    setFirstAvailableOkay(false);
    setIndoorSeatingPreferred(false);
    setBarSeatingPreferred(false);
    setOutdoorSeatingPreferred(true);
  }

  function inputPhoneNumber(event) {
    setPhoneNumber((prevState) =>
      (prevState += event.target.value).slice(0, 10)
    );
  }

  function clearPhoneNumber() {
    setPhoneNumber("");
  }

  function deleteElementPhoneNumber() {
    setPhoneNumber((prevState) => prevState.slice(0, -1));
  }

  function inputName(event) {
    setName(event.target.value);
  }

  function nextChar(c) {
    var next_letter = String.fromCharCode(c.charCodeAt(0) + 1);
    // capital i looks too much like a 1, so skipping
    if (next_letter === "i") {
      return String.fromCharCode(next_letter.charCodeAt(0) + 1);
    } else {
      return next_letter;
    }
  }

  function incrementTicket(company, store_num) {
    axios.get(API_get_ticket + company + "&store=" + store_num).then((df) => {
      var ticketCnt = df.data["ticketCnt"];
      var ticketLetter = df.data["ticketLetter"];
      if (ticketCnt === 10) {
        var newTicketCnt = 0;
        if (ticketLetter === "z") {
          var newTicketLetter = "a";
        } else {
          var newTicketLetter = nextChar(ticketLetter);
        }
      } else {
        var newTicketCnt = ticketCnt + 1;
        var newTicketLetter = ticketLetter;
      }

      //setTicketCnt(newTicketCnt);
      //setTicketLetter(newTicketLetter);
      setTicketNumber(newTicketLetter.toUpperCase() + newTicketCnt.toString());
    });
  }

  function addCustomerToWaitList() {
    if (phoneNumber.length > 0) {
      sendIntroMessage(ticketNumber);
    }
    const new_customer = {
      company: company,
      store_num: store_num,
      ticket_id: ticketNumber,
      customer_id: name === "" ? phoneNumber : name,
      customer_name: name,
      customer_number: phoneNumber,
      party_size: partySize,
      first_available: firstAvailableOkay ? 1 : 0,
      indoor_area: indoorSeatingPreferred ? 1 : 0,
      bar_area: barSeatingPreferred ? 1 : 0,
      outdoor_area: outdoorSeatingPreferred ? 1 : 0,
      notified: 0,
      seated: 0,
      exited: 0,
      quoted_wait_time: 5,
    };
    axios.post(API_add_new_customer, new_customer).then(() => {
      ticketPage();
    });
  }

  function switch_to_home() {
    setPage(1);
  }

  function switch_to_employee_view() {
    setPage(7);
  }

  function toggleFullSceen() {
    if (!document.fullscreenElement) {
      setFullScreen(true);
      document.documentElement.webkitRequestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.webkitExitFullscreen();
        setFullScreen(false);
      }
    }
  }

  function toggleFullScreenButton(color) {
    if (fullScreen) {
      return (
        <FullscreenExitIcon
          style={{
            fontSize: 30,
            position: "absolute",
            top: "10px",
            right: "10px",
            color: color,
          }}
          onClick={() => toggleFullSceen()}
        />
      );
    } else {
      return (
        <FullscreenIcon
          style={{
            fontSize: 30,
            position: "absolute",
            top: "10px",
            right: "10px",
            color: color,
          }}
          onClick={() => toggleFullSceen()}
        />
      );
    }
  }

  function logout_reset() {
    props.logout();
    setStoreNum(0);
    setFinishedLoading(false);
  }

  function get_waitlist_onoff_value() {
    if (partyInputsSettings === null) {
      return true;
    } else {
      if (partyInputsSettings["waitlist_onoff"] === 1) {
        return true;
      } else {
        return false;
      }
    }
  }

  //<NotificationModal sendMessage={sendMessage}/>
  return (
    <div className="App">
      {props.isAuthenticated ? (
        finishedLoading ? (
          page === 1 ? (
            <div>
              {toggleFullScreenButton("black")}
              <SplitscreenIcon
                style={{
                  fontSize: 30,
                  position: "absolute",
                  top: "10px",
                  right: "45px",
                }}
                onClick={() => switch_to_employee_view()}
              />
              <Welcome
                company={company}
                store_num={store_num}
                nextPage={nextPage}
                waitlist_onoff={get_waitlist_onoff_value()}
                email={props.email}
                announcement={
                  partyInputsSettings === null
                    ? ""
                    : partyInputsSettings["announcement"]
                }
              />
            </div>
          ) : page === 2 ? (
            <Party
              partySize={partySize}
              incrementParty={incrementParty}
              decrementParty={decrementParty}
              nextPage={nextPage}
              prevPage={prevPage}
              startPage={startPage}
              timeOutValue={timeOutValue}
              firstAvailableOkay={firstAvailableOkay}
              selectFirstAvailable={selectFirstAvailable}
              indoorSeatingPreferred={indoorSeatingPreferred}
              selectIndoor={selectIndoor}
              barSeatingPreferred={barSeatingPreferred}
              selectBar={selectBar}
              outdoorSeatingPreferred={outdoorSeatingPreferred}
              selectOutdoor={selectOutdoor}
              company={company}
              store_num={store_num}
              firstAvailableOkaySetting={
                partyInputsSettings["first_available_okay"] === 1
              }
              indoorSeatingPreferredSetting={
                partyInputsSettings["indoor_seating_preferred"] === 1
              }
              barSeatingPreferredSetting={
                partyInputsSettings["bar_seating_preferred"] === 1
              }
              outdoorSeatingPreferredSetting={
                partyInputsSettings["outdoor_seating_preferred"] === 1
              }
            />
          ) : page === 3 ? (
            <Name
              prevPage={prevPage}
              nextPage={nextPage}
              inputName={inputName}
              ticketPage={ticketPage}
              timeOutValue={timeOutValue}
              name={name}
              addCustomerToWaitList={addCustomerToWaitList}
            />
          ) : page === 4 ? (
            <Input
              phoneNumber={phoneNumber}
              inputPhoneNumber={inputPhoneNumber}
              clearPhoneNumber={clearPhoneNumber}
              deleteElementPhoneNumber={deleteElementPhoneNumber}
              nextPage={nextPage}
              prevPage={prevPage}
              ticketPage={ticketPage}
              startPage={startPage}
              timeOutValue={timeOutValue}
              addCustomerToWaitList={addCustomerToWaitList}
            />
          ) : page === 5 ? (
            <Ticket
              startPage={startPage}
              timeOutValue={timeOutValue}
              phoneNumber={phoneNumber}
              ticketNumber={ticketNumber}
            />
          ) : (
            <App2
              company={company}
              store_num={store_num}
              switch_to_home={switch_to_home}
              Logout={logout_reset}
              toggleFullScreenButton={toggleFullScreenButton}
              firstAvailableOkaySetting={
                partyInputsSettings["first_available_okay"] === 1
              }
              indoorSeatingPreferredSetting={
                partyInputsSettings["indoor_seating_preferred"] === 1
              }
              barSeatingPreferredSetting={
                partyInputsSettings["bar_seating_preferred"] === 1
              }
              outdoorSeatingPreferredSetting={
                partyInputsSettings["outdoor_seating_preferred"] === 1
              }
              waitlistOnOffSetting={partyInputsSettings["waitlist_onoff"] === 1}
              announcement={
                partyInputsSettings === null
                  ? ""
                  : partyInputsSettings["announcement"]
              }
              savePartyInputOptions={savePartyInputOptions}
              saveAnnouncement={saveAnnouncement}
            />
          )
        ) : (
          <LoadUserInfo getUserInfo={getUserInfo} />
        )
      ) : (
        <Login switch_to_logged_in={switch_to_home} />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.token !== null,
    username: state.user,
    email: state.email,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    logout: () => dispatch(actions.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
