import React from "react"

export default function ArchiveHeader() {
    return (
        <div className="archive-table-header-container">
            <p className="archive-header1">Ticket</p>
            <p className="archive-header2">ID</p>
            <p className="archive-header3">Size</p>
            <p className="archive-header4">Waited</p>
            <p className="archive-header5">Status</p>
            <p className="archive-header6">Action</p>
        </div>
    )
}