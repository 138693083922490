import React, { useState, useEffect } from "react";
import { API_get_waiting_numbers, API_get_waiting_times } from "./constants.js";
import axios from "axios";
import LoadingCircle from "./utilities/LoadingCircle.js";

export default function WaitSummary(props) {
  const [waitingNumbers, setWaitingNumbers] = useState();
  const [waitingTimes, setWaitingTimes] = useState();
  const [loadComplete, setLoadComplete] = useState(false);

  useEffect(() => {
    getWaitingInfo();
  }, [props.actualTableData]);

  function getWaitingInfo() {
    axios
      .get(
        API_get_waiting_numbers + props.company + "&store=" + props.store_num
      )
      .then((df) => {
        setWaitingNumbers(df.data);
        axios
          .get(
            API_get_waiting_times + props.company + "&store=" + props.store_num
          )
          .then((df2) => {
            setWaitingTimes(df2.data);

            setLoadComplete(true);
          });
      });
  }

  function showWaitingNumbers() {
    if (loadComplete) {
      return (
        <h1 className="wait-time-party-number">
          {waitingNumbers["parties"] + waitingNumbers["solo"]}
        </h1>
      );
    } else {
      return <LoadingCircle />;
    }
  }

  function showWaitingTimes() {
    if (loadComplete) {
      return (
        <div className="expected-wait-time-container-values">
          <div className="party-of">
            <h5 className="party-of-title">Party of 1</h5>
            <h4 className="wait-number">
              {waitingTimes["solo_wait"]}
              <span className="wait-mins">mins</span>
            </h4>
          </div>
          <div className="party-of">
            <h5 className="party-of-title">Party of 2</h5>
            <h4 className="wait-number">
              {waitingTimes["two_wait"]}
              <span className="wait-mins">mins</span>
            </h4>
          </div>
          <div className="party-of">
            <h5 className="party-of-title">Party of 3-4</h5>
            <h4 className="wait-number">
              {waitingTimes["three_four_wait"]}
              <span className="wait-mins">mins</span>
            </h4>
          </div>
          <div className="party-of">
            <h5 className="party-of-title">Party of 5+</h5>
            <h4 className="wait-number">
              {waitingTimes["five_plus_wait"]}
              <span className="wait-mins">mins</span>
            </h4>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ marginTop: "50px" }}>
          <LoadingCircle />
        </div>
      );
    }
  }

  return (
    <div className="wait-time-container">
      <div className="wait-time-summary">
        <div>
          <h5 className="wait-time-subtitle">Total Party Waiting</h5>
        </div>
        {showWaitingNumbers()}
      </div>
      <div className="expected-wait-time-container">{showWaitingTimes()}</div>
    </div>
  );
}
