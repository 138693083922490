const BACKEND = process.env.REACT_APP_DB_PATH;

export const API_create_test_message = BACKEND + "/api/create_test_message/";

export const API_get_waiting_customers =
  BACKEND + "/api/waiting_customers?company=";

export const API_get_nonwaiting_customers =
  BACKEND + "/api/nonwaiting_customers?company=";

export const API_add_new_customer = BACKEND + "/api/add_new_customer/";

export const API_seat_customer = BACKEND + "/api/seat_customer?id=";
export const API_notify_customer = BACKEND + "/api/notify_customer?id=";
export const API_notify_customer_again =
  BACKEND + "/api/notify_customer_again?id=";

export const API_remove_customer = BACKEND + "/api/remove_customer?id=";
export const API_put_back_customer = BACKEND + "/api/put_back_customer?id=";
export const API_save_note = BACKEND + "/api/save_note?id=";

export const API_move_to_historical =
  BACKEND + "/api/move_to_historical?company=";

export const API_get_waiting_numbers =
  BACKEND + "/api/waiting_numbers?company=";
export const API_get_waiting_times = BACKEND + "/api/waiting_times?company=";
export const API_get_user_info = BACKEND + "/api/user_info?email=";

export const API_get_table_settings =
  BACKEND + "/api/get_table_settings?company=";
export const API_change_table_settings =
  BACKEND + "/api/change_table_settings/";

export const API_get_party_input_settings =
  BACKEND + "/api/get_party_input_settings?company=";
export const API_change_party_input_settings =
  BACKEND + "/api/change_party_input_settings/";

export const API_get_ticket = BACKEND + "/api/get_ticket?company=";

export const API_REGISTRATION = "none";
export const API_LOGIN = BACKEND + "/api/dj-rest-auth/login/";
export const API_PWD_RESET = BACKEND + "/api/dj-rest-auth/password/reset/";
export const API_PWD_CHANGE = BACKEND + "/api/dj-rest-auth/password/change/";
