import React, { useState, useEffect } from "react";

export default function Name(props) {
  useEffect(() => {
    const timer = setTimeout(() => {
      props.startPage();
    }, props.timeOutValue);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="name-total-container">
      <div className="name-input-container">
        <h1>Let Us Know Your Name</h1>
        <input
          type="text"
          placeholder="Enter your name"
          className="name-input"
          onChange={props.inputName}
        />
      </div>
      <div className="input-button-container">
        <button className="input-back-btn" onClick={props.prevPage}>
          BACK
        </button>
        {props.name.length === 0 ? (
          <button className="input-next-btn0">NEXT</button>
        ) : (
          <button
            className="input-next-btn1"
            // onClick={props.addCustomerToWaitList}
            onClick={props.nextPage}
          >
            NEXT
          </button>
        )}
      </div>
    </div>
  );
}
