import React, { useState } from "react";
import Dashboard from "./Dashboard.js";
import Archive from "./Archive.js";
import Settings from "./Settings.js";
import Navbar from "./Navbar.js";
import SplitscreenIcon from "@mui/icons-material/Splitscreen";

export default function App2(props) {
  const [tab, setTab] = useState(3);
  const [remoteEnabled, setRemoteEnabled] = useState(true);

  function dashboardTab() {
    setTab(1);
  }

  function archiveTab() {
    setTab(2);
  }

  function settingsTab() {
    setTab(3);
  }

  function toggleRemoteEnabled() {
    setRemoteEnabled((prevState) => !prevState);
  }

  return (
    <div>
      {props.toggleFullScreenButton("white")}
      <SplitscreenIcon
        style={{
          fontSize: 30,
          position: "absolute",
          top: "10px",
          right: "45px",
          color: "white",
        }}
        onClick={() => props.switch_to_home()}
      />
      <Navbar
        tab={tab}
        dashboardTab={dashboardTab}
        archiveTab={archiveTab}
        settingsTab={settingsTab}
        switch_to_home={props.switch_to_home}
      />
      {tab === 1 ? (
        <Dashboard company={props.company} store_num={props.store_num} />
      ) : tab === 2 ? (
        <Archive company={props.company} store_num={props.store_num} />
      ) : (
        <Settings
          company={props.company}
          store_num={props.store_num}
          remoteEnabled={remoteEnabled}
          toggleRemoteEnabled={toggleRemoteEnabled}
          Logout={props.Logout}
          firstAvailableOkaySetting={props.firstAvailableOkaySetting}
          indoorSeatingPreferredSetting={props.indoorSeatingPreferredSetting}
          barSeatingPreferredSetting={props.barSeatingPreferredSetting}
          outdoorSeatingPreferredSetting={props.outdoorSeatingPreferredSetting}
          waitlistOnOffSetting={props.waitlistOnOffSetting}
          savePartyInputOptions={props.savePartyInputOptions}
          announcement={props.announcement}
          saveAnnouncement={props.saveAnnouncement}
        />
      )}
    </div>
  );
}
