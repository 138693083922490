import React, { useState, useEffect } from "react";
import Navbar from "./Navbar.js";
import ArchiveHeader from "./ArchiveHeader.js";
import ArchiveRow from "./ArchiveRow.js";
import LoadingCircle from "./utilities/LoadingCircle";
import {
  API_get_nonwaiting_customers,
  API_put_back_customer,
  API_move_to_historical,
} from "./constants.js";
import axios from "axios";

export default function Archive(props) {
  const [actualTableData, setActualTableData] = useState();
  const [loadComplete, setLoadComplete] = useState(false);

  const testTableData = [
    {
      ticket: "K03",
      id: "5214",
      size: "2",
      time: "1",
      timePosted: new Date("March 16, 2022 03:24:00"),
      requests: {
        isBarAreaOkay: true,
        isAnyoneUnder21: false,
        isAccessible: false,
        highChair: true,
        booster: false,
      },
    },
    {
      ticket: "K04",
      id: "Brad",
      size: "4",
      time: "0",
      timePosted: new Date("March 16, 2022 03:25:00"),
      requests: {
        isBarAreaOkay: false,
        isAnyoneUnder21: true,
        isAccessible: true,
        highChair: false,
        booster: true,
      },
    },
    {
      ticket: "KO5",
      id: "4156",
      size: "3",
      time: "4",
      timePosted: new Date("March 16, 2022 03:26:00"),
      requests: {
        isBarAreaOkay: true,
        isAnyoneUnder21: true,
        isAccessible: false,
        highChair: true,
        booster: true,
      },
    },
  ];

  useEffect(() => {
    moveNonWaitingToHistory();
  }, []);

  function moveNonWaitingToHistory() {
    axios
      .get(API_move_to_historical + props.company + "&store=" + props.store_num)
      .then(() => {
        console.log("Moved");
        getNonWaitingCustomers();
      });
  }

  function getNonWaitingCustomers() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    var today_str = yyyy + "-" + mm + "-" + dd;
    axios
      .get(
        API_get_nonwaiting_customers +
          props.company +
          "&store=" +
          props.store_num +
          "&date=" +
          today_str
      )
      .then((df) => {
        setActualTableData(df.data);
        setLoadComplete(true);
      });
  }

  const testArchiveData = [
    {
      ticket: "J70",
      id: "2487",
      size: "2",
      waited: "yes",
      status: "seated",
      putBack: false,
    },
    {
      ticket: "J71",
      id: "Ashton",
      size: "2",
      waited: "yes",
      status: "seated",
      putBack: false,
    },
    {
      ticket: "J72",
      id: "Delta",
      size: "4",
      waited: "no",
      status: "seated",
      putBack: false,
    },
    {
      ticket: "J73",
      id: "2947",
      size: "3",
      waited: "yes",
      status: "seated",
      putBack: false,
    },
  ];

  function putBack(id) {
    axios.put(API_put_back_customer + id).then(() => {
      getNonWaitingCustomers();
    });
    console.log("Put back");
  }
  return (
    <div className="archive-total-container">
      <ArchiveHeader />

      {loadComplete ? (
        actualTableData.map((row) => (
          <ArchiveRow key={row.id} row={row} putBack={putBack} />
        ))
      ) : (
        <LoadingCircle />
      )}
    </div>
  );
}

/*<button onClick={() => moveNonWaitingToHistory()}>Button</button>*/
